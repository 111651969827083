import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useProductGroup } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { updateProductGroupSizes } from "../../api";

const { Title } = Typography;

export const UpdateGroupSize = () => {
  const {
    setIsCreateModal,
    id,
    setId,
    setName,
    setPriority,
    setInternalProductId,
    setInternalName,
    setSize,
    isChangeSizeModal,
    setIsChangeSizeModal,
  } = useProductGroup();

  const resetState = () => {
    setName(null);
    setId(null);
    setInternalName(null);
    setPriority(null);
    setInternalProductId(null);
    setSize(0);
    setIsChangeSizeModal(false);
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    updateProductGroupSizes,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);

          queryClient.invalidateQueries("productGroup");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    const sizeAsInt = parseInt(values.size, 10);
    editMutate({
      size: sizeAsInt || 0,
    });
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Product Group Size</Title>}
      className="app-modal"
      centered
      visible={isChangeSizeModal}
      onCancel={() => {
        resetState();
        setIsChangeSizeModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Product Size"
              name="size"
              rules={[
                { required: true, message: "Size Number is required" },
                {
                  validator: (_, value) =>
                    value < 0
                      ? Promise.reject(new Error("Number must be positive"))
                      : Promise.resolve(),
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                  minWidth: "200px",
                }}
                //min={0}
                placeholder="Size Number"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
