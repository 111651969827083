import { CreditCardOutlined } from "@ant-design/icons";
import { Typography, Space, Row, Col, Tag, Divider } from "antd";
import { useOrderView } from "./store";
import visaLogo from "../../../src/assets/images/visalogo.png";
import masterLogo from "../../../src/assets/images/masterCard.png";
const { Text } = Typography;

export const PaymentInfo = () => {
  const { order } = useOrderView();

  const getPaymentBrandIcon = (brand) => {
    switch (brand?.toLowerCase()) {
      case "visa":
        return (
          <img
            src={visaLogo}
            alt="Visa"
            style={{ width: "40px", height: "auto" }}
          />
        );
      case "mastercard":
        return (
          <img
            src={masterLogo}
            alt="MasterCard"
            style={{ width: "40px", height: "auto" }}
          />
        );
      default:
        return (
          <CreditCardOutlined style={{ fontSize: "20px", color: "#888" }} />
        );
    }
  };

  return (
    <div>
      <Row gutter={[10, 10]} className="bg-gray-200 bg-opacity-50">
        <Col span={10}>
          <Text style={{ fontSize: "12px" }}>Payment Type</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <Tag color="green">{order?.order?.paymentType}</Tag>
                </Space>
              </Text>
            </section>
          </div>
        </Col>
        <Col span={14}>
          <Text style={{ fontSize: "12px" }}>Online Payment Status</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <Tag
                    color={
                      order?.order?.onlinePaymentStatus === "SUCCESS"
                        ? "green"
                        : "red"
                    }
                  >
                    {order?.order?.onlinePaymentStatus}
                  </Tag>
                </Space>
              </Text>
            </section>
          </div>
        </Col>
      </Row>

      {order?.order?.paymentType === "ONLINE" &&
        order?.order?.last4Digits !== "" && (
          <div
            style={{
              padding: "16px",
              width: "100%",
              background: "#fff",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Payment Brand
                </Text>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <Tag color="green">{order?.order?.paymentBrand}</Tag>
                  {getPaymentBrandIcon(order?.order?.paymentBrand)}
                </div>
              </Col>
              <Col span={12}>
                <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Last 4 Digits
                </Text>
                <div style={{ marginTop: "8px" }}>
                  <Text type="secondary" style={{ fontSize: "16px" }}>
                    •••• {order?.order?.last4Digits}
                  </Text>
                </div>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};
