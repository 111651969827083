import {
  Form,
  Button,
  Row,
  Col,
  Divider,
  Space,
  Select,
  InputNumber,
  Checkbox,
} from "antd";

import { useResturant } from "../store";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import {
  getCategories,
  getStatus,
  getFee,
  getDistance,
  getServiceFee,
} from "../../../api";

const { Option } = Select;

export const OtherInfo = () => {
  const {
    id,
    step,
    setStep,
    rank,
    businessCategoryIDS,
    deliveryRangeDistance,
    activeDeliveryRange,
    businessCategories,
    statusCodeID,
    delieveryFeeID,
    deliveryDistanceID,
    serviceFeeID,
    hasPreOrder,
    maxamount,
    minamount,
    setMaxamount,
    setMinamount,
    setRank,
    setStatusCodeID,
    setBusinessCategoryIDS,
    setDeliveryRangeDistance,
    setActiveDeliveryRange,
    setdDelieveryfeeid,
    setServiceFeeID,
    setDeliveryDistanceID,
    setHasPreOrder,
    commission,
    commissionType,
    batching,
    setBatching,
  } = useResturant();
  const [form] = Form.useForm();

  const [catSearch, setCatSearch] = useState("");
  const [feeSearch, setFeeSearch] = useState("");
  const [servicFeeSearch, setServiceFeeSearch] = useState("");

  const {
    isLoading: feeLoading,
    error: feeError,
    data: feeList,
  } = useQuery(["deliverFee", feeSearch], getFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: ServiceFeeLoading,
    error: ServiceFeeError,
    data: ServiceFeeList,
  } = useQuery(["serviceFee", servicFeeSearch], getServiceFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: DistanceLoading,
    error: DistanceError,
    data: DistanceList,
  } = useQuery("deliverDistance", getDistance, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const {
    isLoading: catLoading,
    error: catError,
    data: catList,
  } = useQuery(["categories", catSearch], getCategories, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: statusLoading,
    error: statusError,
    data: statusList,
  } = useQuery("statusCodes", getStatus, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (catList && catList.success) {
      businessCategories?.map((Item) => {
        let cuurentList = catList.data.records.find((el) => el.id == Item.id);
        if (!cuurentList) {
          catList.data.records.push({ id: Item.id, name: Item.name });
        }
      });
    }
  }, [catList]);

  const onFinish = (values) => {
    setStep(step + 1);
  };

  return (
    <div className="app-flex-column">
      <br />
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
          businessCategoryIDS,
          rank,
          deliveryRangeDistance,
          activeDeliveryRange,
          statusCodeID,
          delieveryFeeID,
          deliveryDistanceID,
          serviceFeeID,
          commission,
          commissionType,
          hasPreOrder,
          maxamount,
          minamount,
          batching,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Resturant Type"
              name="businessCategoryIDS"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => {
                  setBusinessCategoryIDS(val.map((el) => el.value));
                }}
                mode="multiple"
                showArrow
                maxTagCount="responsive"
                loading={catLoading}
                error={catError}
                placeholder="Choose Resturant type"
                showSearch
                labelInValue
                filterOption={false}
                onSearch={(val) => {
                  setCatSearch(val);
                }}
              >
                {catList
                  ? catList?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el?.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Resturant State"
              name="statusCodeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setStatusCodeID(val)}
                loading={statusLoading}
                error={statusError}
                placeholder="Choose Status"
              >
                {statusList && statusList.success
                  ? statusList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Delivery Range Distance"
              name="deliveryRangeDistance"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                onChange={(val) => setDeliveryRangeDistance(val)}
                style={{ width: "100%" }}
                addonAfter={"meters"}
              />
            </Form.Item>
          </Col>
          {id && (
            <Col span={8}>
              <Form.Item
                label="Active Delivery Range"
                name="activeDeliveryRange"
                dependencies={["deliveryRangeDistance"]}
                rules={[
                  {
                    required: true,
                    message: "Please input the active delivery range!",
                  },
                  // ({ getFieldValue }) => ({
                  //   validator(_, value) {
                  //     if (
                  //       !value ||
                  //       getFieldValue("deliveryRangeDistance") >= value
                  //     ) {
                  //       return Promise.resolve();
                  //     }
                  //     return Promise.reject(
                  //       new Error(
                  //         "Active Delivery Range must be less than delivery range distance!"
                  //       )
                  //     );
                  //   },
                  // }),
                ]}
              >
                <InputNumber
                  disabled={true}
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                  onChange={(val) => setActiveDeliveryRange(val)}
                  addonAfter={"meters"}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={9}>
            <Form.Item
              label="Delivery Type"
              name="delieveryFeeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setdDelieveryfeeid(val)}
                loading={feeLoading}
                error={feeError}
                placeholder="Choose Delivery Type"
                onSearch={(v) => {
                  setFeeSearch(v);
                }}
                showSearch
                searchValue={feeSearch}
                allowClear
                filterOption={false}
              >
                {feeList
                  ? feeList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Service Fee"
              name="serviceFeeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setServiceFeeID(val)}
                loading={feeLoading}
                error={feeError}
                placeholder="Choose Service Fee"
                onSearch={(v) => {
                  setServiceFeeSearch(v);
                }}
                showSearch
                searchValue={servicFeeSearch}
                allowClear
                filterOption={false}
              >
                {ServiceFeeList
                  ? ServiceFeeList?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el?.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Rank" name="rank" rules={[{ required: true }]}>
              <Select
                onChange={(val) => setRank(val)}
                placeholder="Choose Resturant type"
              >
                <Option value="A">A</Option>
                <Option value="B">B</Option>
                <Option value="C">C</Option>
                <Option value="D">D</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              label="Delivery Distance"
              name="deliveryDistanceID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setDeliveryDistanceID(val)}
                loading={DistanceLoading}
                error={DistanceError}
                placeholder="Choose Delivery Distance"
              >
                {DistanceList
                  ? DistanceList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Max Amount"
              name="maxamount"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                onChange={(val) => setMaxamount(val)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Min Amount"
              name="minamount"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                onChange={(val) => setMinamount(val)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Row span={24}>
            <Form.Item name="hasPreOrder" rules={[{ required: false }]}>
              <Checkbox
                style={{
                  marginLeft: 10,
                }}
                checked={hasPreOrder}
                onChange={(val) => {
                  setHasPreOrder(val.target.checked);
                }}
              >
                Has Pre-Order
              </Checkbox>
            </Form.Item>
            <Form.Item name="batching" rules={[{ required: false }]}>
              <Checkbox
                style={{
                  marginLeft: 20,
                }}
                checked={batching}
                onChange={(val) => {
                  setBatching(val.target.checked);
                }}
              >
                Has Batching orders
              </Checkbox>
            </Form.Item>
          </Row>
          {/* <Col span={6}>
            <Form.Item
              label="Commission"
              name="commission"
              rules={[{ required: true }]}
            >
             <InputNumber
                onChange={(val) => setCommission(val)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Commission Type"
              name="commissionType"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setCommissionType(val)}
                placeholder="Choose type"
              >
                <Option value={"PERCENTAGE"}>Percentage</Option>
                <Option value={"AMMOUNT"}>Amount</Option>
              </Select>
            </Form.Item>
          </Col> */}

          <Col span={24} className="resturant-constroll-form">
            <Divider />
            <Space>
              <Button onClick={() => setStep(step - 1)}>Back</Button>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
