import create from "zustand";

export const useResturant = create((set) => ({
  isCreateModal: false,
  isCreateRadiusModal: false,
  isCloseTimeModal: false,
  isNewClient: false,
  isData: false,
  isClientExest: false,
  isModalFilter: true,
  modalSearch: "",
  step: 0,
  zoom: 10,
  isMapModal: false,
  isSchedual: false,
  workingSchedule: null,
  row: null,
  commissionType: null,
  commission: null,
  page: 1,

  //vendor user info
  id: null,
  name: "",
  username: "",
  phone: "",
  email: "",
  passowrd: "",
  roleID: null,

  // resturant info
  enName: null,
  arName: null,
  kuName: null,
  rank: null,
  provinceID: null,
  cityID: null,
  zoneID: null,
  zone: null,
  city: null,
  address: null,
  ownerName: null,
  businessCategoryIDS: [],
  businessCategories: null,
  resturantPhone: null,
  resturantMgmtPhone: null,
  logoFile: null,
  bannerFile: null,
  deliveryRangeDistance: null,
  activeDeliveryRange: null,
  serviceFeeID: null,
  statusCodeID: null,
  description: null,
  lat: null,
  lon: null,
  isContarctModal: false,
  queryKey: "",
  delieveryFeeID: null,
  deliveryDistanceID: null,
  hasPreOrder: false,
  isFilter: false,
  maxamount: null,
  minamount: null,

  //filters
  provinceIdFilter: null,
  cityIdFilter: null,
  active: null,
  statusCodeIdFilter: null,
  businessCategoryIDsFilter: null,
  rating: false,
  asc: false,

  batching: true,
  setBatching: (batching) => set({ batching }),

  setProvinceIdFilter: (provinceIdFilter) => set({ provinceIdFilter }),
  setCityIdFilter: (cityIdFilter) => set({ cityIdFilter }),
  setActive: (active) => set({ active }),
  setStatusCodeIdFilter: (statusCodeIdFilter) => set({ statusCodeIdFilter }),
  setBusinessCategoryIDsFilter: (businessCategoryIDsFilter) =>
    set({ businessCategoryIDsFilter }),
  setRating: (rating) => set({ rating }),
  setAsc: (asc) => set({ asc }),

  statusSearch: null,
  citySearch: null,
  govsSearch: null,
  changeFilter: null,
  closedUntil: null,

  setGovsSearch: (govsSearch) => set({ govsSearch }),
  setCitySearch: (citySearch) => set({ citySearch }),
  setStatusSearch: (statusSearch) => set({ statusSearch }),
  setChangeFilter: (changeFilter) => set({ changeFilter }),
  setClosedUntil: (closedUntil) => set({ closedUntil }),

  setMaxamount: (maxamount) => set({ maxamount }),
  setMinamount: (minamount) => set({ minamount }),
  setHasPreOrder: (hasPreOrder) => set({ hasPreOrder }),
  setIsFilter: (isFilter) => set({ isFilter }),
  setCity: (city) => set({ city }),
  setCommission: (commission) => set({ commission }),
  setCommissionType: (commissionType) => set({ commissionType }),
  setdDelieveryfeeid: (delieveryFeeID) => set({ delieveryFeeID }),
  setServiceFeeID: (serviceFeeID) => set({ serviceFeeID }),
  setDeliveryDistanceID: (deliveryDistanceID) => set({ deliveryDistanceID }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsContarctModal: (isContarctModal) => set({ isContarctModal }),
  setId: (id) => set({ id }),
  setEnName: (enName) => set({ enName }),
  setArName: (arName) => set({ arName }),
  setKuName: (kuName) => set({ kuName }),
  setRank: (rank) => set({ rank }),
  setProvinceID: (provinceID) => set({ provinceID }),
  setCityID: (cityID) => set({ cityID }),
  setZoneID: (zoneID) => set({ zoneID }),
  setZone: (zone) => set({ zone }),
  setAddress: (address) => set({ address }),
  setOwnerName: (ownerName) => set({ ownerName }),
  setBusinessCategoryIDS: (businessCategoryIDS) => set({ businessCategoryIDS }),
  setBusinessCategories: (businessCategories) => set({ businessCategories }),
  setResturantPhone: (resturantPhone) => set({ resturantPhone }),
  setResturantMgmtPhone: (resturantMgmtPhone) => set({ resturantMgmtPhone }),
  setLogoFile: (logoFile) => set({ logoFile }),
  setBannerFile: (bannerFile) => set({ bannerFile }),
  setDeliveryRangeDistance: (deliveryRangeDistance) =>
    set({ deliveryRangeDistance }),
  setActiveDeliveryRange: (activeDeliveryRange) => set({ activeDeliveryRange }),
  setStatusCodeID: (statusCodeID) => set({ statusCodeID }),
  setDescription: (description) => set({ description }),
  setLat: (lat) => set({ lat }),
  setLon: (lon) => set({ lon }),

  setName: (name) => set({ name }),
  setUsername: (username) => set({ username }),
  setPhone: (phone) => set({ phone }),
  setEmail: (email) => set({ email }),
  setPassword: (passowrd) => set({ passowrd }),
  setStep: (step) => set({ step }),
  setIsNewClient: (isNewClient) => set({ isNewClient }),
  setIsData: (isData) => set({ isData }),
  setIsClientExest: (isClientExest) => set({ isClientExest }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setIsCreateRadiusModal: (isCreateRadiusModal) => set({ isCreateRadiusModal }),
  setIsCloseTimeModal: (isCloseTimeModal) => set({ isCloseTimeModal }),
  setIsModalFilter: (isModalFilter) => set({ isModalFilter }),
  setModalSearch: (modalSearch) => set({ modalSearch }),
  setRoleID: (roleID) => set({ roleID }),
  setZoom: (zoom) => set({ zoom }),
  setIsMapModal: (isMapModal) => set({ isMapModal }),
  setIsSchedule: (isSchedual) => set({ isSchedual }),
  setWorkingSchedule: (workingSchedule) => set({ workingSchedule }),
  setRow: (row) => set({ row }),
  setPage: (page) => set({ page }),
}));
