import { Button, Divider, Upload, Space, message } from "antd";
import { useResturant } from "../store";
import {
  SmileOutlined,
  PictureOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-query";
import { queryClient } from "../../../queryClient";
import { createResturant } from "../../../api";
import { listSchedule } from "../../../comonents/global";

const { Dragger } = Upload;

export const GetImages = () => {
  const {
    step,
    setStep,
    setUsername,
    setIsNewClient,
    setEmail,
    setName,
    setPhone,
    setIsCreateModal,
    setIsClientExest,
    delieveryFeeID,
    setdDelieveryfeeid,
    maxamount,
    minamount,
    setMaxamount,
    setMinamount,
    deliveryDistanceID,
    setDeliveryDistanceID,
    setServiceFeeID,
    serviceFeeID,
    setHasPreOrder,
    hasPreOrder,
    batching,
    setIsModalFilter,
    arName,
    rank,
    provinceID,
    cityID,
    zoneID,
    address,
    ownerName,
    businessCategoryIDS,
    resturantPhone,
    resturantMgmtPhone,
    logoFile,
    bannerFile,
    deliveryRangeDistance,
    activeDeliveryRange,
    statusCodeID,
    description,
    lat,
    lon,
    setLat,
    setLon,
    setBannerFile,
    setLogoFile,
    setOwnerName,
    setProvinceID,
    setCityID,
    setZoneID,
    setStatusCodeID,
    setDeliveryRangeDistance,
    setActiveDeliveryRange,
    setResturantPhone,
    setResturantMgmtPhone,
    setDescription,
    setBusinessCategoryIDS,
    setAddress,
    setArName,
    setRank,
    commission,
    commissionType,
    setCommission,
    setCommissionType,
    setBatching,
  } = useResturant();

  const { mutate, isLoading } = useMutation(createResturant, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("resturants");
        setIsClientExest(false);
        setIsCreateModal(false);
        setIsNewClient(false);
        setHasPreOrder(false);
        setIsModalFilter(true);
        setUsername("");
        setPhone("");
        setName("");
        setEmail("");
        setLat("");
        setLon("");
        setBannerFile("");
        setLogoFile("");
        setOwnerName(null);
        setProvinceID(null);
        setCityID(null);
        setZoneID(null);
        setStatusCodeID(null);
        setDeliveryRangeDistance(null);
        setActiveDeliveryRange(null);
        setResturantPhone(null);
        setResturantMgmtPhone(null);
        setDescription(null);
        setBusinessCategoryIDS([]);
        setAddress(null);
        setArName(null);
        setRank(null);
        setdDelieveryfeeid(null);
        setDeliveryDistanceID(null);
        setServiceFeeID(null);
        setMaxamount(null);
        setMinamount(null);
        setCommission(null);
        setCommissionType(null);
        setStep(0);
        setBatching(true);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleSave = async () => {
    let formData = {
      arName,
      enName: arName,
      kuName: arName,
      rank,
      provinceID,
      cityID,
      zoneID,
      address,
      ownerName,
      businessCategoryIDS: JSON.stringify(businessCategoryIDS),
      resturantPhone,
      resturantMgmtPhone,
      logoFile,
      bannerFile,
      deliveryRangeDistance: deliveryRangeDistance / 1000,
      active_delivery_range: deliveryRangeDistance / 1000, // deliveryRangeDistance instaed of activeDeliveryRange
      statusCodeID,
      description,
      active: true,
      lat,
      lon,
      commission,
      commissionType,
      delieveryFeeID,
      deliveryDistanceID,
      serviceFeeID,
      maxamount,
      minamount,
      hasPreOrder,
      batching,
      workingSchedule: JSON.stringify(listSchedule),
    };

    mutate(formData);
  };

  const bannerProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setBannerFile(file);
      return false;
    },
  };
  const logoProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setLogoFile(file);
      return false;
    },
  };

  return (
    <>
      <br />
      <Dragger {...logoProps}>
        <p className="ant-upload-drag-icon">
          <SmileOutlined />
        </p>
        <p className="ant-upload-text">Brand</p>
        <p className="ant-upload-hint">
          Click or drag Image to this area to upload
        </p>
      </Dragger>
      <br />
      <br />
      <Dragger {...bannerProps}>
        <p className="ant-upload-drag-icon">
          <PictureOutlined />
        </p>
        <p className="ant-upload-text">Cover Image</p>
        <p className="ant-upload-hint">
          Click or drag Image to this area to upload
        </p>
      </Dragger>
      <div
        className="resturant-constroll-form"
        style={{ paddingRight: 10, paddingLeft: 10 }}
      >
        <Divider />
        <Space>
          <Button onClick={() => setStep(step - 1)}>Back</Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSave}
          >
            Finish
          </Button>
        </Space>
      </div>
    </>
  );
};
