import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { usePaymentType } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createPaymentType, editPaymentType } from "../../api";

const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const { isCreateModal, setIsCreateModal, id, setId, name, setName,description ,setDescription} =
    usePaymentType();

  const resetstate = () => {
    setId(null);
    setName(null);
    setDescription(null);
  };

  const { mutate, isLoading } = useMutation(createPaymentType, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("paymentTypes");
        resetstate();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editPaymentType,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          setId(null);
          queryClient.invalidateQueries("paymentTypes");
          resetstate();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  //'VENDOR','CLIENT','BIKER','BACKOFFICE_USER'

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Payment Type</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetstate();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        //form={form}
        initialValues={{
          remember: true,
          name,
          description
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
          <Form.Item label="Payment Type" name="name" rules={[{ required: true }]}>
              <Select placeholder="Select payment type">
                <Option value="CASH">CASH</Option>
                <Option value="ONLINE">ONLINE</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
              <Input placeholder="Write the description" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
