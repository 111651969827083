import { Typography, Modal, Space } from "antd";
import { useResturant } from "./store";
import { ResturantInfo, GetLocation, GetImages, OtherInfo } from "./forms";
import { useEffect } from "react";

const { Title, Text } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    step,
    id,
    setId,
    setLat,
    setLon,
    setOwnerName,
    setProvinceID,
    setCityID,
    setCity,
    setZoneID,
    setZone,
    setStatusCodeID,
    setDeliveryRangeDistance,
    setActiveDeliveryRange,
    setResturantPhone,
    setResturantMgmtPhone,
    setDescription,
    setBusinessCategoryIDS,
    setAddress,
    setArName,
    setRank,
    setStep,
    setdDelieveryfeeid,
    setDeliveryDistanceID,
    setServiceFeeID,
    setMaxamount,
    setMinamount,
    setHasPreOrder,
    setBusinessCategories,
    setBatching,
  } = useResturant();

  let renderSteps = id
    ? [<ResturantInfo />, <OtherInfo />, <GetLocation />]
    : [
        // <ClientForm />,
        <ResturantInfo />,
        <OtherInfo />,
        <GetLocation />,
        <GetImages />,
      ];

  const stepStyle = id
    ? [{ width: "25%" }, { width: "50%" }, { width: "100%" }]
    : [
        // {width: 0},
        { width: "25%" },
        { width: "50%" },
        { width: "75%" },
        { width: "100%" },
      ];

  useEffect(() => {
    if (!id) {
      setStep(0);
      setOwnerName(null);
      setProvinceID(null);
      setCityID(null);
      setCity(null);
      setZoneID(null);
      setZone(null);
      setStatusCodeID(null);
      setDeliveryRangeDistance(null);
      setActiveDeliveryRange(null);
      setResturantPhone(null);
      setResturantMgmtPhone(null);
      setDescription("");
      setBusinessCategoryIDS([]);
      setAddress(null);
      setArName(null);
      setRank(null);
      setLat(null);
      setLon(null);
      setdDelieveryfeeid(null);
      setDeliveryDistanceID(null);
      setServiceFeeID(null);
      setMaxamount(null);
      setMinamount(null);
      setHasPreOrder(false);
      setBusinessCategories(null);
      setBatching(true);
    }
  }, [id]);

  return (
    <Modal
      title={
        <Space style={{ width: "100%" }} direction="vertical">
          <Title style={{ marginBottom: 0 }} level={5}>
            {id ? "Edit" : "Create"} Resturant
          </Title>
          <Text type="secondary">
            {id
              ? "Edit a resturant information and click finish"
              : "Create a resturant for a costumer directly from reception"}
          </Text>
        </Space>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        setId(null);
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
      bodyStyle={{ minHeight: 600, paddingTop: 10 }}
      maskClosable={false}
    >
      <div
        className="step-line"
        style={{ display: step == 0 ? "block" : "flex" }}
      >
        <span className={"step-line-select"} style={stepStyle[step]}></span>
      </div>
      <br />
      {renderSteps[step]}
    </Modal>
  );
};
