import { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Popover,
  Alert,
  Menu,
  Dropdown,
  Card,
  message,
  Modal,
  Pagination,
  Switch,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  ShoppingCartOutlined,
  ContactsOutlined,
  MoreOutlined,
  DownOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";
import {
  getResturants,
  removeResturant,
  pageSize,
  updateVendorStatus,
  getStatus,
  editVendorStatus,
  ChangeHybridPaymentStatus,
} from "../../api";
import { PureMapModal } from "./mapModal";
import { WorkingSchedule } from "./workingSchedule";
import { useResturant } from "./store";
import { useNavigate } from "react-router-dom";
import { ImageTable } from "../../comonents/global";
import { useSearchParams } from "react-router-dom";
const { Text, Title } = Typography;

export const PureTable = () => {
  const {
    setIsMapModal,
    setArName,
    setIsCreateModal,
    setOwnerName,
    setProvinceID,
    setResturantMgmtPhone,
    setResturantPhone,
    setAddress,
    setDescription,
    setCityID,
    setZoneID,
    setStatusCodeID,
    setDeliveryRangeDistance,
    setActiveDeliveryRange,
    setRank,
    setBusinessCategoryIDS,
    setLat,
    setLon,
    setId,
    setStep,
    queryKey,
    setdDelieveryfeeid,
    setDeliveryDistanceID,
    setServiceFeeID,
    setHasPreOrder,
    setIsSchedule,
    setRow,
    setWorkingSchedule,
    setCommission,
    setCommissionType,
    setCity,
    setMaxamount,
    setMinamount,
    setZone,
    setBusinessCategories,
    setPage,
    page,
    setIsData,
    setIsCloseTimeModal,
    setClosedUntil,

    //filters
    provinceIdFilter,
    cityIdFilter,
    active,
    statusCodeIdFilter,
    businessCategoryIDsFilter,
    rating,
    asc,
    setBatching,
  } = useResturant();

  const [selected, setIsSelected] = useState(null);
  // const [page, setPage] = useState(1);
  const [selectedID, setSelectedID] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setStep(0);
    setId(row.id);
    setArName(row.arName);
    setIsCreateModal(true);
    setOwnerName(row.ownerName);
    setProvinceID(row.provinceID);
    setResturantMgmtPhone(row.resturantMgmtPhone);
    setResturantPhone(row.resturantPhone);
    setAddress(row.address);
    setDescription(row.description);
    setCityID(row.cityID);
    setZoneID(row.zoneID);
    setZone(row.zone);
    setCity(row.city);
    setStatusCodeID(row.statusCodeID);
    setDeliveryRangeDistance(row.deliveryRangeDistance * 1000);
    setActiveDeliveryRange(row.active_delivery_range * 1000);
    setRank(row.rank);
    setBusinessCategoryIDS(
      row.businessCategories ? row.businessCategories.map((el) => el.id) : []
    );
    setBusinessCategories(row.businessCategories);
    setdDelieveryfeeid(row.delieveryFeeID);
    setDeliveryDistanceID(row.deliveryDistanceID);
    setServiceFeeID(row.serviceFeeID);
    setHasPreOrder(row.hasPreOrder);
    setLat(row.lat);
    setLon(row.lon);
    setWorkingSchedule(row.workingSchedule);
    setCommission(row.commission);
    setCommissionType(row.commissionType);
    setMaxamount(row.maxamount);
    setMinamount(row.minamount);
    setBatching(row.batching);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 65,
    },
    {
      title: "Logo",
      key: "id",
      width: 65,
      render: (row) => (
        <ImageTable
          logo={true}
          url={row.logoImg}
          id={row.id}
          pathName={"vendor/logo"}
          imgKey={"logoFile"}
          updateStore={"resturants"}
        />
      ),
    },
    {
      title: "Banner",
      key: "id",
      width: 100,
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.bannerImg}
          id={row.id}
          pathName={"vendor/banner"}
          imgKey={"bannerFile"}
          updateStore={"resturants"}
        />
      ),
    },

    {
      title: "Name",
      dataIndex: "arName",
      key: "arName",
      ellipsis: true,
      render: (text, row) => (
        <Popover
          placement="topLeft"
          content={
            <Space direction="vertical" size={0}>
              <Text>
                <b>{row.arName}</b>
              </Text>
              <Text type="secondary">{row.description || "....."}</Text>
            </Space>
          }
        >
          <Text style={{ fontWeight: "bold" }}>{text}</Text>
        </Popover>
      ),
    },
    {
      title: "Region",
      dataIndex: "city",
      key: "city",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <Text type="secondary" style={{ fontSize: 12 }}>
            {text}
          </Text>
        </Tooltip>
      ),
    },

    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
      ellipsis: true,
    },
    {
      title: "Owner Phone",
      dataIndex: "resturantMgmtPhone",
      key: "resturantMgmtPhone",
      ellipsis: true,
      render: (text, row) => (
        <Text style={{ fontSize: 12 }}>{row.resturantMgmtPhone}</Text>
      ),
    },
    {
      title: "Resturant Phone",
      dataIndex: "resturantPhone",
      key: "resturantPhone",
      ellipsis: true,
      render: (text, row) => (
        <Text style={{ fontSize: 12 }}>{row.resturantPhone}</Text>
      ),
    },
    {
      title: "Resturant Type",
      dataIndex: "businessCategories",
      key: "businessCategories",
      ellipsis: true,
      render: (val) => (
        <Popover
          content={
            val
              ? val.map((el) => (
                  <Tag key={el.id} color="geekblue">
                    {el.name}
                  </Tag>
                ))
              : "..."
          }
        >
          {val
            ? val.map((el) => (
                <Text key={el.id} color="geekblue">
                  {el.name}
                </Text>
              ))
            : "..."}
        </Popover>
      ),
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 50,
    },

    {
      title: "Status",
      key: "id",
      width: 60,
      render: (row) => (
        <Switch
          // size="small"
          disabled={authFunc("VENDOR_UPDATE") ? false : true}
          loading={selectedID === row.id && editLoading}
          checked={row.active ? true : false}
          onChange={() => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Hybrid Payment",
      key: "id",
      width: 120,
      align: "center",
      render: (row) => (
        <Switch
          disabled={authFunc("HYBRID_PAYMENT_UPDATE") ? false : true}
          loading={HybridPaymenLoading}
          checked={row.hybridPaymentEnabled}
          onChange={() => handleHybridPayment(row)}
        />
      ),
    },
    {
      title: "Resturant State",
      dataIndex: "StatusCode",
      key: "StatusCode",
      render: (StatusCode, row) => (
        <Dropdown
          overlay={menu(row)}
          disabled={authFunc("VENDOR_UPDATE") ? false : true}
        >
          <Button size="small" type="link">
            {StatusCode} <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      width: 70,
      align: "right",

      render: (id, row) => (
        <Dropdown
          overlay={
            <Card bodyStyle={{ padding: 0 }}>
              {authFunc("PRODUCT_READ") && (
                <div
                  className="resturant-action-item"
                  onClick={() => navigate(`/resturants/${id}/products`)}
                >
                  <Space>
                    <ShoppingCartOutlined />
                    Products List
                  </Space>
                </div>
              )}

              {authFunc("VENDOR_CONTRACT_READ") && (
                <div
                  className="resturant-action-item"
                  onClick={() => navigate(`/resturants/${id}/contracts`)}
                >
                  <Space>
                    <ContactsOutlined />
                    Vendor Contracts
                  </Space>
                </div>
              )}

              {authFunc("VENDOR_UPDATE") && (
                <div
                  className="resturant-action-item"
                  onClick={() => {
                    setIsSchedule(true);
                    setWorkingSchedule(
                      row.workingSchedule == "{}"
                        ? null
                        : JSON.parse(row.workingSchedule)
                    );
                    setRow(row);
                  }}
                >
                  <Space>
                    <CalendarOutlined />
                    Working Days
                  </Space>
                </div>
              )}

              {authFunc("VENDOR_UPDATE") && (
                <div
                  className="resturant-action-item"
                  onClick={() => {
                    setIsCloseTimeModal(true);
                    setClosedUntil(row?.closed_until);
                    setRow(row);
                  }}
                >
                  <Space>
                    <FieldTimeOutlined />
                    Close Time
                  </Space>
                </div>
              )}

              {authFunc("VENDOR_UPDATE") && (
                <div
                  className="resturant-action-item"
                  onClick={() => {
                    setId(row.id);
                    setIsMapModal(true);
                    setIsSelected(row);
                  }}
                >
                  <Space>
                    <EnvironmentOutlined />
                    Resturant Location
                  </Space>
                </div>
              )}

              {authFunc("VENDOR_UPDATE") && (
                <div
                  className="resturant-action-item"
                  onClick={() => handleEdit(row)}
                >
                  <Space>
                    <EditOutlined />
                    Edit Info
                  </Space>
                </div>
              )}

              {authFunc("VENDOR_DELETE") && (
                <div
                  className="resturant-action-item resturant-action-item-danger"
                  onClick={() => {
                    Modal.confirm({
                      loading: true,
                      content: (
                        <div>
                          <Title level={5}>Confirm</Title>
                          <Text>Are you sure to delete this Resturant ?</Text>
                        </div>
                      ),

                      onOk: () => mutate(id),
                    });
                  }}
                >
                  <Space>
                    <DeleteOutlined />
                    Delete Resturant
                  </Space>
                </div>
              )}
            </Card>
          }
          placement="bottomRight"
        >
          <Button
            style={{ fontSize: 20 }}
            type="text"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      ),
    },
  ];
  const { mutate: HybridPaymentMutate, isLoading: HybridPaymenLoading } =
    useMutation(ChangeHybridPaymentStatus, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("pwaSettings");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
  const handleHybridPayment = (row) => {
    HybridPaymentMutate({
      paymentObject: {
        hybridPaymentEnabled: !row.hybridPaymentEnabled,
        id: row.id,
      },
      route: "vendor",
    });
    row.hybridPaymentEnabled = !row.hybridPaymentEnabled;
  };
  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeResturant(val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("resturants");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    [
      "resturants",
      queryKey,
      page,
      provinceIdFilter,
      10,
      cityIdFilter,
      active,
      statusCodeIdFilter,
      businessCategoryIDsFilter,
      rating,
      asc,
    ],
    getResturants,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data && data.success && data?.data?.total !== 0) {
      setIsData(true);
    } else {
      setIsData(false);
    }
  }, [data]);

  const { data: statusList } = useQuery("statusCodes", getStatus, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const menu = (row) => (
    <Menu onClick={(e) => editVendorState(e, row)}>
      {statusList?.data?.records?.map((el) => (
        <Menu.Item key={el.id}>{el.name}</Menu.Item>
      ))}
    </Menu>
  );

  const editVendorState = (e, row) => {
    setSelectedID(row.id);
    let data = {
      id: row.id,
      statusCodeID: Number(e.key),
    };
    editStateMutate(data);
  };

  const { mutate: editStateMutate } = useMutation(editVendorStatus, {
    onSuccess: (data) => {
      setSelectedID(null);
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("resturants");
      } else {
        message.error(data.err);
      }
    },
    onError: (e) => {
      setSelectedID(null);
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    updateVendorStatus,
    {
      onSuccess: (data) => {
        setSelectedID(null);
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("resturants");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    setSelectedID(row.id);
    let data = {
      id: row.id,
      active: !row.active,
    };
    editMutate(data);
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading || addLoading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
      <PureMapModal data={selected} />
      <WorkingSchedule data={selected} />
    </>
  );
};
