import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useProductGroup } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createProductGroup,
  editProductGroup,
  getInternalProduct,
} from "../../api";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    name,
    id,
    setId,
    setName,
    priority,
    setPriority,
    internal_product_groups_id,
    setInternalProductId,
    setInternalName,
    setSize,
    size,
  } = useProductGroup();

  const resetState = () => {
    setName(null);
    setId(null);
    setInternalName(null);
    setPriority(null);
    setInternalProductId(null);
    setSize(0);
  };
  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["InternalProduct", 1, 1000], getInternalProduct, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const { mutate, isLoading } = useMutation(createProductGroup, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("productGroup");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editProductGroup,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);

          queryClient.invalidateQueries("productGroup");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Product Group</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          name,
          priority,
          internal_product_groups_id,
          size,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Category Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Group name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                placeholder="Priority Number"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Product Size"
              name="size"
              rules={[
                { required: true, message: "Size Number is required" },
                {
                  validator: (_, value) =>
                    value < 0
                      ? Promise.reject(new Error("Number must be positive"))
                      : Promise.resolve(),
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                  minWidth: "200px",
                }}
                //min={0}
                placeholder="Size Number"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Internal Product Name"
              name="internal_product_groups_id"
              //rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setInternalProductId(val)}
                loading={loading}
                placeholder="Choose Internal Product Name"
                allowClear
                filterOption={false}
              >
                {data?.data.records &&
                  data?.data.records.map((el) => (
                    <Option key={el.id} value={el.id}>
                      {el.internal_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              // rules={[{ required: true }]}
            >
              <TextArea rows={4} placeholder={"Group Description"} />
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
